

















































































import {defineComponent, PropType} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import AssistedGradingWritten from '@/common/components/AssistedGradingWritten.vue';
import Task from '@/tasks/models/Task';
import {TaskType} from '@/tasks/types/TaskType';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import VueApexCharts from 'vue-apexcharts/dist/vue-apexcharts';
import {UseConcordiaExperiment1SlopeIntercept} from '@/tasks/composables/UseConcordiaExperiment1SlopeIntercept';
import {inject} from '@/container';

export default defineComponent({
  name: 'ConcordiaChem206Experiment1Part3',
  components: {StembleLatex, AssistedGradingWritten, ApexChart: VueApexCharts},
  mixins: [DynamicQuestionMixin()],
  props: {
    task: {
      type: Object as PropType<Task<TaskType.DynamicQuestion>>,
      required: true,
    },
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props) {
    const {inputs, colDefs, equation, apexSeries, apexOptions, addRun, removeRun} = inject(
      UseConcordiaExperiment1SlopeIntercept,
      {volume: 'Volume (L)', temperature: 'Temperature (C)'},
      'volume',
      'temperature',
      'volumeTemp',
      props.isMarking,
      'V',
      'T',
      0.1,
      0.3,
      0,
      80
    );

    return {
      volumeTempColDefs: colDefs,
      charlesLaw: equation,
      inputs,
      apexOptions,
      apexSeries,
      addRun,
      removeRun,
    };
  },
});
